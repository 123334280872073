.goal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    
    width: 300px;
    height: 500px;
    max-width: 300px;
    max-height: 500px;
    padding: 20px;
    
    color: white;
    background-color: var(--main-bg-color);
    border: 1px solid var(--secondary-theme-color);
    border-radius: 25px;
}

.goal .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: inherit;
    width: inherit;
}

.goal .content .title{
    position: relative;
    margin-bottom: auto;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: bold;
}

.goal .content .progressbar{
    margin-top: auto;
    margin-bottom: auto;
}

.goal .content .money{
    margin-top: auto;
}

.goal.complete .progressbar .bar{
    background: conic-gradient(var(--main-theme-color) 360deg, var(--main-bg-color) 0deg) !important;
}

.goal.complete .progressbar .point{
    background: conic-gradient(var(--main-theme-color) 360deg, var(--main-bg-color) 0deg) !important;
}

.goal .options{
    display: none;
    position: absolute;
    flex-direction: column;
    gap: 30px;
    z-index: 3;
}

.goal .options .remove-btn{
    background-color: var(--warning-color);
}

.goal .options .remove-btn:hover{
    background-color: var(--secondary-theme-color);
}

.goal .options .remove-btn:active{
    background-color: var(--warning-color);
}

.goal.hover .options{
    display: flex;
}

.goal.hover .content{
    filter: blur(10px);
}

@media (max-width: 600px) {
    .goal{
        height: 200px;
        width: auto;
        margin: 20px;
    }

    .goal .progressbar{
        width: 100px;
    }
    
    .goal .progressbar .bar-container .bar-bg{
        width: 60px;
    }
}