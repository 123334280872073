.balance {
  display: flex;
  flex-direction: column;

  align-items: center;

  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

.balance .title {
  font-size: 50px;
  transform: translateY(30px);
  color: var(--secondary-theme-color);
}

#balance {
  font-size: 90px;

  color: var(--main-theme-color);
}

#actionDialog {
  flex-direction: column;
  align-items: center;
  position: absolute;
  height: auto;
  width: 300px;

  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  background-color: var(--main-bg-color);
  border: 1px solid var(--secondary-theme-color);
  border-radius: 50px;

  transition: border-color .2s;
}

#actionDialog[open] {
  display: flex;
}

#blur {
  position: absolute;
  z-index: 1;

  width: 100%;
  height: 100%;

  top: 0;
  left: 0;

  pointer-events: none;

  transition: .2s ease-out;

  --blur-strength: 5px;
}

#blur.hidden {
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
}

#blur.shown {
  backdrop-filter: blur(var(--blur-strength));
  -webkit-backdrop-filter: blur(var(--blur-strength));
}