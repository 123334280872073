select {
  width: 70%;
  height: 50px;

  background-color: var(--main-bg-color);
  border: 1px solid var(--secondary-theme-color);
  border-radius: 20px;

  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;

  color: var(--secondary-theme-color);
}

select option {
  font-weight: bold;
  font-size: 18px;
}