.section {
  max-width: 60%;
}

.section {
  animation: show 1s ease;
}

.section.right {
  margin-left: auto;
}

.section.right h2 {
  text-align: right;
}

.section.center {
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
}

.section.center h2 {
  text-align: center;
}

.section .title {
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  margin: 5px;
}

.section .content {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  margin: 15px;
}

.section .more {
  font-family: "Fugaz One", sans-serif;
  font-size: 22px;
  color: var(--main-theme-color);
}

@media (max-width: 600px) {
  .section {
    max-width: 80%;
  }

  .section .title {
    font-size: 23px;
  }
}

@keyframes show {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}