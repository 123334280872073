.footer {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 2rem;
  width: 100%;
  height: 75px;

  position: fixed;
  bottom: 0;
  z-index: 900;

  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: white;

  border-top: 1px solid var(--secondary-theme-color);

  background-color: var(--main-bg-color);
}