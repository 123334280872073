:root {
  --main-bg-color: #0C0C0C;
  --main-theme-color: #00FF66;
  --main-theme-color-rgb: 0, 255, 102;
  --main-theme-color-accent: #5FFF9F;
  --secondary-theme-color: #FFFFFF;
  --warning-color: #FF3838;
  --warning-color-rgb: 255, 56, 56;
}

::-webkit-scrollbar{
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(var(--main-theme-color-rgb), .5);
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--main-theme-color)
}

body {
  background-color: var(--main-bg-color);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.body {
  padding-top: 120px;
  padding-bottom: 75px;
  margin: 0 50px 0 50px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: white;
}