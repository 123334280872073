.navbar {
  display: flex;

  width: 100%;
  height: 100px;

  position: fixed;
  transform: translateX(-1px);
  z-index: 900;

  background-color: var(--main-bg-color);
  border-left: 1px solid var(--secondary-theme-color);
  border-right: 1px solid var(--secondary-theme-color);
  border-bottom: 1px solid var(--secondary-theme-color);
  border-radius: 0 0 50px 50px;

  transition: all ease .5s;
}

.navbar.moved {
  border-radius: 50px;
  border: 1px solid var(--secondary-theme-color);
  transform: translateY(10px);
  scale: .98;
}

.hero {
  overflow: hidden;

  font-family: 'Fugaz One', sans-serif, cursive;
  font-size: 36px;
  color: white;
}

.hero .hero-title {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 50px;
}

.hero .title {
  display: flex;
}

.accent {
  color: var(--main-theme-color);
}

.dollar {
  position: fixed;
  z-index: 0;

  margin-left: 25px;
  top: 25%;

  transform: rotate(-31deg);

  scale: 3.3;

  color: var(--main-theme-color);
  opacity: .09;
}

.navbar .routes {
  display: flex;

  align-items: center;

  margin-left: auto;
  margin-right: 50px;

  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: bold;
  gap: 40px;
  z-index: 999;
}

.navbar .routes a {
  color: var(--secondary-theme-color);
  text-decoration: none;
  transition: color .2s;
}

.navbar .routes a:hover {
  color: var(--main-theme-color-accent);
}

.navbar .routes .active {
  color: var(--main-theme-color);
}

.navbar .routes a:focus {
  color: var(--main-theme-color);
}

@media (max-width: 600px) {
  .hero .hero-title {
    display: none;
  }
}

.username {
  color: var(--secondary-theme-color);
  text-transform: uppercase;
}