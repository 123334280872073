#closeDialogButton {
  width: 14px;
  height: 14px;
  background-color: var(--warning-color);
  border-radius: 50%;
  margin-left: auto;
  margin-right: 20px;
  margin-top: 14px;

  cursor: pointer;

  transition: background-color .2s;

  margin-bottom: 25px;
}

#closeDialogButton:hover {
  background-color: var(--secondary-theme-color);
}

dialog .title {
  margin-right: auto;
  margin-left: 15%;
  margin-bottom: 2px;

  color: var(--secondary-theme-color);

  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: bold;
}

dialog select {
  margin-bottom: 40px;
}

dialog input {
  width: 70%;
  height: 50px;

  background-color: var(--main-bg-color);
  border: 1px solid var(--secondary-theme-color);
  border-radius: 20px;

  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;

  color: var(--secondary-theme-color);
  margin-bottom: 40px;
}

dialog .btn {
  margin-bottom: 14px;
}

dialog:has(.btn:hover) {
  border-color: var(--main-theme-color) !important;
}

dialog:has(#closeDialogButton:hover) {
  border-color: var(--warning-color) !important;
}